import { queryServices } from '@wix/ambassador-bookings-services-v2-service/build/cjs/http.impl';
import {
  QueryServicesRequest,
  LocationType,
  SortOrder,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ReservedLocationIds } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ServiceListSettings } from '../../legacy/appSettings/appSettings';
import { buildQueryServicesFilter } from '../utils/filters/buildQueryServicesFilter';

type QueryServicesRequestQuery = QueryServicesRequest['query'];
type QueryServicesRequestFilters = QueryServicesRequestQuery['filter'];
type QueryServicesRequestPaging = QueryServicesRequestQuery['paging'];

export type QueryServicesFilter = {
  staffMemberIds?: string[];
  categoryIds?: string[];
  serviceIds?: string[];
  locationIds?: (string | typeof ReservedLocationIds.OTHER_LOCATIONS)[];
  limit?: number;
};

export class BookingsAPI {
  private flowAPI: ControllerFlowAPI;
  private appSettings: ServiceListSettings;
  private shouldWorkWithAppSettings: boolean;

  constructor({
    appSettings,
    flowAPI,
    shouldWorkWithAppSettings,
  }: {
    flowAPI: ControllerFlowAPI;
    appSettings: ServiceListSettings;
    shouldWorkWithAppSettings: boolean;
  }) {
    this.flowAPI = flowAPI;
    this.appSettings = appSettings;
    this.shouldWorkWithAppSettings = shouldWorkWithAppSettings;
  }

  async queryServices() {
    const {
      categoryIds,
      locationIds,
      serviceIds,
      staffMemberIds,
      limit = 500,
    } = buildQueryServicesFilter({
      flowAPI: this.flowAPI,
      shouldWorkWithAppSettings: this.shouldWorkWithAppSettings,
      appSettings: this.appSettings,
    });
    const paging: QueryServicesRequestPaging = { limit };
    let filter: QueryServicesRequestFilters = {};
    const hiddenFilter = {
      $or: [{ hidden: false }, { hidden: { $exists: false } }],
    };
    const orFilters: QueryServicesRequestFilters[] = [hiddenFilter];

    if (staffMemberIds) {
      filter.staffMemberIds = staffMemberIds;
    }

    const serviceIdsFilter = {
      id: {
        $in: serviceIds,
      },
    };
    const categoryIdsFilter = {
      'category.id': {
        $in: categoryIds,
      },
    };
    if (serviceIds && categoryIds) {
      orFilters.push({
        $or: [serviceIdsFilter, categoryIdsFilter],
      });
    } else if (serviceIds) {
      filter = {
        ...filter,
        ...serviceIdsFilter,
      };
    } else if (categoryIds) {
      filter = {
        ...filter,
        ...categoryIdsFilter,
      };
    }

    if (locationIds) {
      const includeNonBusinessLocations = locationIds.includes(
        ReservedLocationIds.OTHER_LOCATIONS,
      );
      const businessLocationIds = locationIds.filter(
        (locationId) => locationId !== ReservedLocationIds.OTHER_LOCATIONS,
      );

      const otherLocationsFilter = {
        'locations.type': { $in: [LocationType.CUSTOM, LocationType.CUSTOMER] },
      };
      const businessLocationFilter = {
        'locations.business.id': {
          $in: businessLocationIds,
        },
      };

      if (includeNonBusinessLocations && businessLocationIds.length > 0) {
        orFilters.push({ $or: [businessLocationFilter, otherLocationsFilter] });
      } else if (includeNonBusinessLocations) {
        filter = { ...filter, ...otherLocationsFilter };
      } else {
        filter = { ...filter, ...businessLocationFilter };
      }
    }

    if (orFilters.length > 1) {
      filter.$and = orFilters;
    } else {
      const [f] = orFilters;
      filter.$or = (f as any).$or;
    }

    return this.flowAPI.httpClient.request(
      queryServices({
        query: {
          sort: [
            {
              fieldName: 'category.sortOrder',
              order: SortOrder.ASC,
            },
            {
              fieldName: 'sortOrder',
              order: SortOrder.ASC,
            },
          ],
          paging,
          filter,
        },
      }),
    );
  }
}
